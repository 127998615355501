body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.map-marker-wrapper {}

.map-marker {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  pointer-events: all;
  z-index: 1000000;
}

.map-marker.hovered {
  z-index: 1000001;
}

.map-marker-icon {
  overflow: hidden;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  background-size: cover;
  border: 3px solid #ffffff;
  border-radius: 60px;
  display: block;
  pointer-events: all;
  z-index: 1000000;
}

.map-marker-icon:hover, .map-marker-icon.hovered {
  height: 60px;
  width: 60px;
  margin-top: -20px;
  margin-left: -20px;
  z-index: 1000001;
}